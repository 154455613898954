<template>
    <div class="lct-detail-content">
        <div class="lct-detail-head">
            <div class="lct-detail-head-main">
                <div class="lct-detail-head-crumbs" v-if="info.title">
                    <span class="on">直播</span><i class="iconfont icon-jiantou"></i><router-link to="/lct">讲座会议</router-link><i class="iconfont icon-jiantou"></i><span>{{ info.title }}</span>
                </div>

                <div class="new-detail-head">
                    <div class="lct-detail-head-banner" v-if="info.banner">
                        <!--<img :src="info.banner"/>-->
                        <el-image style="width: 100%;" :src="info.banner" fit="cover"></el-image>
                    </div>
                    <div class="new-detail-info">
                        <div class="lct-detail-head-title">
                            <h4>{{ info.title }}</h4>
                            <div class="share-show">
                                <img class="wx" src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/weixin@2x.png"/>
                                <div class="vue-qr-box">
                                    <vue-qr :text="info.live_id?downloadData.url:downloadData.url2" colorLight="#fff" :logoSrc="downloadData.icon + '?cache'" :logoScale="0.2" :size="200" :margin="20"></vue-qr>
                                </div>
                            </div>
                        </div>
                        <div class="lct-detail-head-author" v-if="info.author"><strong>主讲嘉宾</strong><span>{{ info.author }}</span></div>
                        <!--<div><strong>单位</strong><span>{{ info.author }}</span></div>-->
                        <div class="lct-detail-head-rank_sf">
                            <div class="lct-detail-head-rank_sf-l" v-if="info.rank_sf">
                                <strong>观看权限</strong>
                                <span v-for="(item,index) in info.rank_sf.split(',')" :key="index">{{ item }}</span>
                            </div>
                            <div class="lct-detail-head-rank_sf-c" v-if="info.study_count">
                                已有{{ info.study_count }}人报名
                            </div>

                        </div>

                        <div class="lct-detail-head-rank_sf-btn" v-if="info.rank && info.rank.indexOf(0) == -1">
                            <button @click="toPath(info.is_buy == 0?true:false)">{{ info.is_buy == 0?'立即报名':'立即观看' }}</button>
                        </div>
                        <div class="lct-detail-head-rank_sf-btn" v-else>
                            <button @click="toPath(info.is_buy == 0?true:false)">立即观看</button>
                        </div>
                    </div>
                </div>




            </div>
        </div>

        <div class="lct-detail-intro" v-if="info.details">
            <div class="lct-detail-intro-title">
                <strong>课程介绍</strong>
            </div>
            <div v-html="info.details"></div>
        </div>

        <el-dialog :visible.sync="payVisible" title="学习联盟" width="400px" :footer="null">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/index/approve`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">请先关注公众号去实名认证</p>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="payVisible2" title="学习联盟" width="400px" :footer="null">
            <div class="modal-txt">
                <vue-qr class="vue-qr" :margin="0" :text="`${baseUrl}/ysxxlm/index.html#/pages/live/z_details?id=${info.live_id}&share=1`" colorDark="#000" colorLight="#fff" :size="200"/>
                <p style="margin-top: 20px;">此课程您还未付费，请去微信扫码去公众号购买此直播</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import vueQr from "vue-qr";

    import config from '@/api/url'
    export default {
        components: {
            'vue-qr': vueQr
        },
        data() {
            return {
                info: {},
                isdjbm: false,
                payVisible: false,
                payVisible2: false,
                baseUrl: config.baseUrl,
                downloadData: {
                    url: `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/live/z_details?id=${this.$route.params.id}&share=1`,
                    url2: `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/live/d_details?id=${this.$route.params.id}&share=1`,
                    icon: require('../../assets/img/logo.jpg')
                }
            }
        },
        watch:{
            // $route(to,from){
            //     if(to.query.code) {
            //         setTimeout( ()=> {
            //             if(this.isdjbm) {
            //                 setTimeout(()=> {
            //                     this.buy();
            //                 },500)
            //             }
            //             this.getDetail();
            //         },1000)
            //     }
            // },
			'$store.state.uid'(value){
				if(this.isdjbm) {
					setTimeout(()=> {
						this.buy();
					},500)
				}
				this.getDetail();
			}
        },
        computed: {
            userInfo() {
                let userInfo = '';
                if(this.$store.state.userInfo && this.$store.state.userInfo != undefined){
                    userInfo = this.$store.state.userInfo
                }
                return userInfo
            },
        },
        mounted() {
            this.getDetail();
        },
        methods: {
            getDetail() {
                this.$axios.post(this.apiUrl.courseDetails, {
                    id: this.$route.params.id
                }).then(res => {
                    this.info = res.data;
                    this.downloadData.url = `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/live/z_details?id=${this.info.live_id}&share=1`
                })
            },
            toPath(v) {
				if(this.info.price!="0.00"&&(!this.userInfo || this.userInfo == undefined)){
					this.isdjbm = true;
					this.$store.commit('wxLogin',true);
					return false
				}
                if(this.info.rank.indexOf(0) == -1 && (!this.userInfo || this.userInfo == undefined)) {
                    this.isdjbm = true;
                    this.$store.commit('wxLogin',true);
                    return false
                }
                if(this.info.rank.indexOf(0) == -1 && this.info.can_watch == 0) {
                    this.$message.error('抱歉,您没有观看权限,请去实名认证')
                    this.payVisible = true;
                    return false
                }
				
                if(this.info.rank.indexOf(0) == -1) {
                    this.buy();
                }else{
					if(this.info.price=="0.00"){
						this.$router.push({
						    path: `/seminars/${this.$route.params.id}/plays`
						})
					}else{
						this.buy();
					}
                }
            },
            buy() {
                this.$axios.post(this.apiUrl.buyCourse, {
                    id: this.$route.params.id
                }).then(res => {
                    if(res.code == 1){
                        if(this.info.is_buy == 0) {
                            this.$message.success('报名成功');
                        }
                        this.$router.push({
                            path: `/seminars/${this.$route.params.id}/plays`
                        })
                    }else {
                        this.payVisible2 = true
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
